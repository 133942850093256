import React, { Component } from 'react';
import EmailsTable from './EmailsTable';
import ComposeEmailDialog from './ComposeEmailDialog';
import EmailDetails from './EmailDetails';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import history from '../../core/history';

class EmailsInMonitor extends Component {
  state = {
    detailsOpened: false,
    composeNewOpened: false,
    emailId: null,
  }
  componentDidMount() {
    if (this.props.isFleetMonitor) {
      const { bidId } = this.props;
      if (bidId && bidId !== '-') {
        this.handleOpenDetails(bidId);
      }
    }
  }

  handleOpenDetails = (emailId) => {
    if (this.props.isFleetMonitor) {
      if (this.props.tcOffer) {
        history.push(`/$1/$2/$3/${emailId}/$5`);
      } else {
        if(this.props.isMyVessel) history.push(`/$1/$2/$3/$4/${emailId}`);
        else history.push(`/$1/$2/$3/${emailId}`);
      }
    }
    this.setState({ detailsOpened: true, emailId: emailId });
  }
  handleCloseDetails = () => {
    if (this.props.isFleetMonitor) {
      if (this.props.tcOffer) {
        history.push(`/$1/$2/$3/-/$5`);
      } else {
        if(this.props.isMyVessel) history.push(`/$1/$2/$3/$4`);
        else history.push(`/$1/$2/$3/`);
      }
    }
    this.setState({ detailsOpened: false, emailId: null });
  }
  handleOpenCompose = () => {
    this.setState({ composeNewOpened: true });
  }
  handleCloseCompose = () => {
    this.setState({ composeNewOpened: false });
  }
render() {
  const { detailsOpened, composeNewOpened, emailId } = this.state;
  
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DialogHalfScreen noEddie position={this.props.position} open>
        <EmailsTable
          isMonitor
          isContactBook={this.props.isContactBook}
          emailsForFilter={this.props.emailsForFilter}
          cargoId={this.props.cargoId}
          vesselId={this.props.vesselId}
          handleCloseTable={this.props.closeMails}
          openEmailDetails={this.handleOpenDetails}
          openCompose={this.handleOpenCompose}
          isFleetMonitor={this.props.isFleetMonitor}
        />
      </DialogHalfScreen>
      {detailsOpened &&
        <DialogHalfScreen noEddie position={this.props.position} open>
          <EmailDetails
            cargoId={this.props.cargoId}
            vesselId={this.props.vesselId}
            emailId={emailId}
            handleClose={this.handleCloseDetails}
            showRecognizedItems={false}
            mailFlowProps={{ transitionLockerPredicate: (location, action) => location.pathname.indexOf(`/${this.props.cargoId}/`) === -1 }}
            shouldConfirmClose
            action={this.props.action}
          />
        </DialogHalfScreen>
      }
      {composeNewOpened &&
        <ComposeEmailDialog
        composeProps={{ to: this.props.emailsForFilter }}
          cargoId={this.props.cargoId}
          vesselId={this.props.vesselId}
          onClose={this.handleCloseCompose}
          position={this.props.position}
        />
      }
    </div>
  );
}
}

export default EmailsInMonitor;
