import React from 'react';
import Formsy from 'formsy-react';
import cx from 'classnames';
import { onEnterNextInput, stopEnterPropagation } from './utils';
import formsyUtils from 'formsy-react/lib/utils';
import createReactClass from 'create-react-class';

const Input = createReactClass({
  // Add the Formsy Mixin
  mixins: [{ ...Formsy.Mixin,
    setValue: function (value = '', pristine = this.props.calculated) {
     if (value === this.state._value && !this.state.focused) {
        return;
      }
      this.setState({
        _value: value,
        _isPristine: pristine,
        changing: pristine,
      }, function () {
        this.context.formsy.validate(this);
        //this.props._validate(this);
      });
      if (pristine) {
        setTimeout(()=>{this.setState({ changing: false })}, 300);
      }
    },
    componentDidUpdate: function(prevProps, prevState, prevContext) {

      if (!formsyUtils.isSame(this.props.value, prevProps.value)) {
        if(this.props.numeric && isNaN(this.props.value)) {
          this.setValue('');
        }else {
          this.setValue(this.props.value);
        }

      }

      // If validations or required is changed, run a new validation
      if (!formsyUtils.isSame(this.props.validations, prevProps.validations) || !formsyUtils.isSame(this.props.required, prevProps.required)) {
        this.context.formsy.validate(this);
      }
    },
    formatOnChange,
  }],
  getDefaultProps() {
    return {
    maximumFractionDigits:2,
    minimumFractionDigits:0,
    onChangeDelay: 1000,
    locale: "ru-RU",
    separator: ' ',
    decimalSeparator: ',',
  }},

  // setValue() will set the value of the component, which in
  // turn will validate it and the rest of the form
  changeValue(event) {
    let value = event.currentTarget.value || '';

    if (this.props.dontModifyValue) {
      const pv = this.getValue();
      let start = event.currentTarget.selectionStart,
        end = event.currentTarget.selectionEnd;
      event.currentTarget.value = value;
      event.currentTarget.setSelectionRange(start, end);
      if (this.props.numeric !== false && value !== '') {
        let isnum = /^[\d,.]+$/.test(value);
        if (!isnum) {
          return;
        }
      }
      this.setValue(
        value, false      );
      if (this.getValue() !== value && this.props.onChange) {
        this.props.onChange(event, value, pv);
      }
      return;
    }
    if (this.props.numeric !== false && value !== '') {
      const { formattedValue, numValue } = this.formatOnChange(event);
      if (formattedValue === null && numValue === null) {
        return;
      }
      if (numValue === null) {
        clearTimeout(this.commitTimer);
        return this.setValue(
          formattedValue,
          false,
        );
      }
      value = numValue;
    }

    const pv = this.getValue();

    this.setValue(
      value,
      false
    );
    if (this.getValue() !== value && this.props.onChange) {
      this.shouldReportValueOnBlur = true;
      clearTimeout(this.commitTimer);
      this.commitTimer = setTimeout(() => {
        this.props.onChange(event, value, pv);
        this.commitTimer = null;
        this.pv = value;
      }, this.props.onChangeDelay);
    }
  },
  handleFocus() {
    this.setState({ focused: true }, () => {
      this.refs.input.setSelectionRange(0,1024)
    });

    this.pv = this.getValue();
  },
  handleBlur(e) {
    this.setState({ focused: false });
    let val = this.getValue();
    if (this.props.numeric){
      if (typeof val === 'string') {
        if (val.indexOf(',') === 0) {
          val = '0' + val;
        }
        val = parseFloat(val);
      }
      if (isNaN(val)) {
        return;
      }
    }
    if(this.props.onBlur) {
      this.props.onBlur(this.shouldReportValueOnBlur ? val : undefined)
    }
    if(this.commitTimer) {
      clearTimeout(this.commitTimer);
      this.props.onChange(e, val, this.pv);
    }
    this.shouldReportValueOnBlur = false;

  },
  handleKeyUp(e) {
    return onEnterNextInput(e);
  },
  handleKeyDown(e) {
    return stopEnterPropagation(e);
  },

  render() {
    const { className, name, unit = '', manualIfEmpty, ...restProps } = this.props;
    const errorMessage = this.getErrorMessage();
    let calculated = this.props.calculated;
    let value = this.getValue();
    let isEmpty;
    if (value === '' || value === null || value === undefined || value === 0) {
      isEmpty = true;
    }
   /* if (manualIfEmpty && !value) {
      calculated = false;
    }*/
    if (!this.state.focused && unit && value) {
      value += unit;
    }
    try {
      if (!unit && this.props.numeric && value && isFinite(value) && value.toLocaleString) {
        let minimumFractionDigits = this.minimumFractionDigits || 0;
        if (!this.state.focused) {
          minimumFractionDigits = Math.max(minimumFractionDigits, this.props.minimumFractionDigits);
        }
        value = value.toLocaleString(this.props.locale, { maximumFractionDigits: this.props.maximumFractionDigits, minimumFractionDigits });
      }
    } catch (e) {
    }
    const hasError = (this.showError() || !this.state._isValid);
    return (
      <div className={cx(className, 'input-container', this.showRequired() && 'required', hasError && 'error', this.state._isPristine && 'pristine', calculated && 'calculated', this.state.changing && 'changing', this.props.required && 'required', isEmpty && 'empty', (hasError && !isEmpty) && 'errored-not-empty')}>
        <input
          type={this.props.type || 'text'}
          name={name}
          {...restProps}
          onChange={this.changeValue}
          value={value === undefined ? "" : value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          autoComplete="off"
          spellCheck="off"
          autoCorrect="off"
          ref={'input'}
          title={errorMessage || restProps.title}
        />
        <span className="validation-error">{errorMessage}</span>
      </div>
    );
  },
});

export default Input;


export function formatOnChange(event){
  let value = event.currentTarget.value;
  const originalValue = value;
  if (this.props.numeric !== false && value !== '') {
    if (this.props.decimalSeparator !== '.') {
      value = value.replace(this.props.decimalSeparator, '.');
    }
    if (this.props.separator !== ',') {
      value = value.replace(',', '.');
    } else {
      if (value[value.length - 1] === ',') {
        value = value.slice(0, -1) + '.';
      }
    }
    value = value.replace('..', '.');
    if (value === '.') {
      value = '0.';
    }
    let minimumFractionDigits = 0;
    let dotIndex = value.indexOf('.');
    const isDotLast = dotIndex === value.length - 1;
    if (dotIndex === value.length - 1) {
      dotIndex--;
    }
    if (dotIndex > -1) {
      minimumFractionDigits = Math.min(this.props.maximumFractionDigits, value.length - dotIndex - 1);
    }
    this.minimumFractionDigits = minimumFractionDigits;
    if (isDotLast/* || value[0] === '.' || value === '0.0' || /0\.0+$/.test(value)*/) {
      return { formattedValue: value.replace('.', this.props.decimalSeparator), numValue: null };
    }
    let start = event.currentTarget.selectionStart,
      end = event.currentTarget.selectionEnd;

    value = value.replace(/[^1234567890.]/g,'');
    value = parseFloat(value);
    if (isNaN(value)) {
      return { formattedValue: null, numValue: null };
    }
    const formattedValue = value.toLocaleString(this.props.locale, {maximumFractionDigits: this.props.maximumFractionDigits, minimumFractionDigits});
    event.currentTarget.value = formattedValue;
    const cursorPositionDiff = (originalValue.length - formattedValue.length)
    event.currentTarget.setSelectionRange(Math.max(start - cursorPositionDiff,0) , Math.max(end - cursorPositionDiff, 0));
    return { formattedValue, numValue: value };
  }
}
