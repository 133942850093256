/* eslint-disable import/prefer-default-export */

import {
  CLOSELOGIN,
  COMMON_COMMENT_SAVE,
  LOGIN,
  LOGIN_CONFIRM_EMAIL,
  LOGIN_FEEDBACK,
  LOGIN_GET_COUNTERS,
  LOGIN_GET_FORGOT_LINK,
  LOGIN_REQUEST_CONFIRM_EMAIL,
  LOGIN_SUBSCRIBED,
  LOGIN_TOGGLE_SUBSCRIBE_DIALOG,
  LOGIN_UNSUBSCRIBE,
  LOGIN_VALIDATE_EMAIL,
  LOGOUT,
  OPENLOGIN,
  PROFILE_ACCEPTGDPR, PROFILE_ADD_AUTOTAG,
  PROFILE_APPROVE_MEMBER,
  PROFILE_CANCEL_SUBSCRIPTION,
  PROFILE_CHANGE_PAYMENT_CARD,
  PROFILE_DELETE_ACCOUNT, PROFILE_DELETE_AUTOTAG,
  PROFILE_DELETE_MEMBER,
  PROFILE_DESTROY,
  PROFILE_GET, PROFILE_GET_AUTOTAGS,
  PROFILE_GET_FREE,
  PROFILE_GET_INVITE_INFO,
  PROFILE_GET_LIMITATIONS, PROFILE_GET_MAILGATES_STATE, PROFILE_GET_MEMBERS_TAGS, PROFILE_GET_TAGS_CATEGORIES,
  PROFILE_PARTIAL_UPDATE, PROFILE_SAVE_AUTOTAG, PROFILE_SAVE_MEMBERS_TAGS, PROFILE_SAVE_PREFERENCES,
  PROFILE_TOGGLE_LIMITATIONS,
  PROFILE_UPDATE,
  PROFILE_UPDATE_BLACKLIST,
  PROFILE_UPDATE_WHITELIST,
  PROFILE_UPSERT_ACCOUNT,
  USER_STATS,
  PROFILE_GET_LAST_FILTERS,
  PROFILE_SAVE_LAST_FILTERS,
  PROFILE_GET_ACCOUNTS,
  OPEN_GLOBAL_COMPOSE_DIALOG,
  CLOSE_GLOBAL_COMPOSE_DIALOG,
  PROFILE_GET_ACCOUNT,
  ACCOUNT_COMPLIANCE_CREATE,
  ACCOUNT_COMPLIANCE_SIGN,
  ACCOUNT_COMPLIANCE_SAVE,
  ACCOUNT_COMPLIANCE_DELETE,
  PROFILE_SYNC_KYC_ACCOUNT
} from '../constants/login';
import { REGISTER } from '../constants/register';
import { getAndClearCookie, prepareRequest } from '../core/utils';
import { User } from '../core/api/api';
import Mercurion from '../core/api/Mercurion';
import { PROFILE_CHANGE_PASSWORD } from '../constants/profile';
import CommentsApi from '../core/api/CommentsApi';
import TagsApi from '../core/api/Tags';

/**
 * login action
 * @param email
 * @param password
 * @returns {Object}
 */
export function login({ email, password }, afterQueue = []) {
  const redirectCookie = getAndClearCookie('redirectAfterLogin');
  afterQueue.push(getCounters);
  return {
    type: LOGIN,
    promise: User.login(email, password),
    redirect: { route:  redirectCookie || '/main-deck/general' },
    afterQueue,
  };
}

/**
 * register action
 * @param fullName
 * @param email
 * @param password
 * @param rest
 * @param afterQueue
 * @returns {Object}
 */
export function register(
  { fullName, email, password, ...rest },
  afterQueue = [],
) {
  getAndClearCookie('redirectAfterLogin');
  return {
    type: REGISTER,
    promise: User.register({ fullName, email, password, gdprComplained: true, ...rest }),
    redirect: { route: '/main-deck/general' },
    afterQueue,
  };
}



/**
 * logout action
 * @returns {Object}
 */
export function logout() {
  try {
    getAndClearCookie('redirectAfterLogin');
    sessionStorage.removeItem('monitor.deckCargoFilter.dirty');
    sessionStorage.removeItem('monitor.deckVesselFilter.dirty');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('deckCargoFilterTabId');
  } catch (e) {
    console.error(e);
  }
  return {
    type: LOGOUT,
    promise: User.logout().finally((res) => {
      window.location = '/';
      return res
    }),
  };
}

export function logoutWithoutRedirect(){
  try {
    sessionStorage.removeItem('monitor.deckCargoFilter.dirty');
    sessionStorage.removeItem('monitor.deckVesselFilter.dirty');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('deckCargoFilterTabId');
  } catch (e) {
    console.error(e);
  }
  return {
    type: LOGOUT,
    promise: User.logout().finally((res) => {
      return res
    }),
  };
}

export function openLogin() {
  return {
    type: OPENLOGIN,
    payload: {},
  };
}

export function closeLogin() {
  return {
    type: CLOSELOGIN,
    payload: {},
  };
}

export function updateProfile(newProfile) {
  return {
    type: PROFILE_UPDATE,
    promise: User.updateProfile(prepareRequest(newProfile)),
    payload: newProfile,
    successMessage: 'Profile updated',
  };
}

export function getProfile(token) {
  return {
    type: PROFILE_GET,
    promise: User.profile(token),
  };
}

export function getStatistic({page, numberOfRows}) {
  return {
    type: USER_STATS,
    promise: User.statistic(page, numberOfRows),
    page,
    numberOfRows
  }
}

export function changePassword({ oldPassword, newPassword }) {
  return {
    type: PROFILE_CHANGE_PASSWORD,
    promise: User.changePassword({ oldPassword, newPassword }),
    successMessage: 'password changed',
    redirect: { route: '/settings/profile' },
  };
}

export function sentLink({ email }) {
  return {
    type: LOGIN_GET_FORGOT_LINK,
    promise: User.requestForgotLink({ email }),
    redirect: { route: '/login' },
    successMessage: {
      level: 'success',
      message: 'A password reset link has been sent to your email ',
      position: 'tc',
      autoDismiss: false,
    },
  };
}

export function changeForgotPassword({ newPassword, token }) {
  return {
    type: LOGIN,
    promise: User.changeForgotPassword({ password: newPassword, token }),
    successMessage: {
      level: 'success',
      message: 'Password changed',
      position: 'tc',
      autoDismiss: false,
    },
    redirect: { route: '/settings/profile' },
  };
}

export function toggleSubscribeDialog(open) {
  return {
    type: LOGIN_TOGGLE_SUBSCRIBE_DIALOG,
    payload: open,
  };
}
export function subscribe() {
  return {
    type: LOGIN_SUBSCRIBED,
    payload: true,
  };
}

export function getAccounts() {
  return {
    type: PROFILE_GET_ACCOUNTS,
    promise: User.getAccounts(),
  };
}

export function upsertAccount(account, companyId) {
  return {
    type: PROFILE_UPSERT_ACCOUNT,
    payload: account,
    promise: User.upsertAccount(account),
  };
}

export function syncKycAccount(data,departmentId){
  return {
    type: PROFILE_SYNC_KYC_ACCOUNT,
    payload: data,
    promise: User.syncKycAccount(data,departmentId),
    afterQueue: [refreshDepartment(departmentId)],
  }
}

export function deleteAccount(account) {
  return {
    type: PROFILE_DELETE_ACCOUNT,
    payload: account,
    promise: User.deleteAccount(account),
  };
}

function refreshDepartment(id) {
  return function (res) {
    return function (dispatch, getState) { dispatch(getDepartmentInfo(id)); };
  };
}

export function getDepartmentInfo(accountId){
  return {
      type: PROFILE_GET_ACCOUNT,
      payload: accountId,
      promise: Mercurion.getDepartmentInfo(accountId)
  }
}

export function createKYC(data,accountId){
  return {
      type: ACCOUNT_COMPLIANCE_CREATE,
      payload: data,
      promise: Mercurion.createKYC(data,accountId),
      afterQueue: [refreshDepartment(accountId)],
    };
}

export function signKYC(data,accountId){
  return {
      type: ACCOUNT_COMPLIANCE_SIGN,
      payload: data,
      promise: Mercurion.signKYC(data,accountId),
      afterQueue: [refreshDepartment(accountId)],
  };
}

export function saveComplianceForm(data,accountId){
  return {
      type: ACCOUNT_COMPLIANCE_SAVE,
      payload: data,
      promise: Mercurion.saveComplianceForm(data,accountId),
      afterQueue: [refreshDepartment(accountId)],
  };
}

export function deleteComplianceForm(accountId){
  return {
      type: ACCOUNT_COMPLIANCE_DELETE,
      payload: accountId,
      promise: Mercurion.deleteComplianceForm(accountId),
      afterQueue: [refreshDepartment(accountId)],
  }
}

export function approveMember(member, companyId) {
  return {
    type: PROFILE_APPROVE_MEMBER,
    payload: member,
    promise: User.approveMember(member, companyId),
  };
}

export function deleteMember(member, companyId) {
  return {
    type: PROFILE_DELETE_MEMBER,
    payload: member,
    promise: User.deleteMember(member, companyId),
  };
}

export function uploadImage(formData, opts) {
  return {
    type: 'UPLOAD_IMAGE',
    payload: formData,
    promise: User.uploadImage(formData, opts),
  };
}
export function validateEmail(email, corporate) {
  return {
    type: LOGIN_VALIDATE_EMAIL,
    payload: email,
    promise: User.validateEmail(email, corporate),
    silent: true,
  };
}
export function requestConfirmEmail() {
  return {
    type: LOGIN_REQUEST_CONFIRM_EMAIL,
    promise: User.requestConfirmEmail(),
    successMessage: { level: 'success', message: 'Verification email sent' },
    messageType: 'DIALOG_MESSAGE',
  };
}

export function confirmEmail(token, user) {
  return {
    type: LOGIN_CONFIRM_EMAIL,
    payload: token,
    promise: User.confirmEmail(token),
    messageType: 'DIALOG_MESSAGE',
    forceSuccessMessage: true,
    afterQueue: [
      profile => (dispatch) => {
        dispatch({ type: LOGIN, res: profile, payload: profile.data });
      },
      getCounters
    ],
  };
}

export function unsubscribe(token) {
  return {
    type: LOGIN_UNSUBSCRIBE,
    payload: token,
    promise: User.unsubscribe(token),
    successMessage: {
      level: 'success',
      message: 'You have successfully unsubscribed from SHIPNEXT mailing list',
      position: 'tc',
      autoDismiss: false,
    },
    errorMessage: {
      400: 'Invalid or expired link',
    },
  };
}
export function sendFeedback(feedback) {
  return {
    type: LOGIN_FEEDBACK,
    promise: User.sendFeedback(feedback),
    successMessage: 'Thank you, your message has been sent successfully!',
  };
}

export function showMessage(messageObj) {
  return {
    type: "MESSAGE",
    payload: messageObj
  }
}

export function getCounters(profile) {
  return (dispatch, getState) => {
    const state = getState();
    const user = profile || state.login.user;
    if(!user) {
      return;
    }
      dispatch({
        type: LOGIN_GET_COUNTERS,
        promise: User.getCounters(),
        silent: true,
      });
  };
}

export function getLimitations(token) {
  return {
    type: PROFILE_GET_LIMITATIONS,
    promise: User.getLimitations(token),
  };
}
export function updateWhiteList(emails) {
  return {
    type: PROFILE_UPDATE_WHITELIST,
    promise: User.updateWhitelist(emails),
  };
}
export function updateBlackList(emails) {
  return {
    type: PROFILE_UPDATE_BLACKLIST,
    promise: User.updateBlacklist(emails),
  };
}
export function toggleLimitations(data) {
  return {
    type: PROFILE_TOGGLE_LIMITATIONS,
    promise: User.toggleLimitations(data),
  };
}

export function openSubscribeDialog() {
  return {
    type: 'LOGIN_TOGGLE_SUBSCRIBE_DIALOG',
    payload: true };
}

export function closeSubscribeDialog() {
  return {
    type: 'LOGIN_TOGGLE_SUBSCRIBE_DIALOG',
    payload: false
  };
}
export function handleGlobalComposeDialog(open) {
  if (open) {
    return {
      type: OPEN_GLOBAL_COMPOSE_DIALOG,
    };
  }
  return {
    type: CLOSE_GLOBAL_COMPOSE_DIALOG,
  };
}

export function acceptGDPR() {
  return {
    type: PROFILE_ACCEPTGDPR,
    promise: User.acceptGDPR() };
}

export function selfDestroy(shouldDestroy) {
  if (!shouldDestroy) {
    return { type: '' };
  }
  return {
    type: PROFILE_DESTROY,
    promise: User.selfDestroy() };
}

export function getInviteInfo(key, token) {
  return {
    type: PROFILE_GET_INVITE_INFO,
    promise: User.getInviteInfo(key, token),
  };
}

export function partialProfileUpdate(diff) {
  return {
    type: PROFILE_PARTIAL_UPDATE,
    payload: diff,
  };
}

export function getFreeAccess(params) { //TODO e.fedorov remove this function
  console.warn('deprecated function getFreeAccess');
  return;
  return {
    type: PROFILE_GET_FREE,
    promise: User.getFreeAccess(params),
  };
}

export function changePaymentCard(){
  return {
    type: PROFILE_CHANGE_PAYMENT_CARD,
    promise: User.changePaymentCard(),
  };
}

export function cancelSubscription({ answerText,comment }) {
  return {
    type: PROFILE_CANCEL_SUBSCRIPTION,
    promise: _cancelSubscription({ answerText,comment })
  };
}

async function _cancelSubscription({ answerText,comment }) {
  const res = await User.cancelSubscription();
  try {
    await User.unsubscribeSurvey({ answerText, comment })
  } catch (e) {
  }
  return res;
}

export function saveComment(payload) {
  return {
    type: COMMON_COMMENT_SAVE,
    promise: CommentsApi.saveComment(payload),
    payload,
    successMessage: 'Comment added successfully',
  };

}
export function saveContactComment(payload) {
  return {
    type: COMMON_COMMENT_SAVE,
    promise: CommentsApi.saveContactComment(payload),
    payload,
    successMessage: 'Comment added successfully',
  };

}

export function savePreferences(partial) {
  return {
    type: PROFILE_SAVE_PREFERENCES,
    promise: User.saveAccountPreferences(partial),
    payload: partial
  }
}

export function getMailGatesState(){
  return {
    type: PROFILE_GET_MAILGATES_STATE,
    promise: User.getMailGatesState(),
    payload: {},
    silent: true,
  }
}

export function getAutoTags(entity){
  return {
    type: PROFILE_GET_AUTOTAGS,
    promise: TagsApi.getAutotags(entity),
    payload: { entity },
  }
}

export function deleteAutotag(entity, autotag){
  return {
    type: PROFILE_DELETE_AUTOTAG,
    promise: autotag.isNew ? null : TagsApi.deleteAutotag(entity, autotag),
    payload: { entity, autotag },
  }
}

export function saveAutotag(entity, autotag){
  return {
    type: PROFILE_SAVE_AUTOTAG,
    promise: TagsApi.saveAutotag(entity, { ...autotag }),
    payload: { entity, autotag },
  }
}

export function addAutotag(entity, autotag){
  return {
    type: PROFILE_ADD_AUTOTAG,
    payload: { entity, autotag },
  }
}

export function getTagsCategories(entity = 'cargoRequest'){
  return {
    type: PROFILE_GET_TAGS_CATEGORIES,
    promise: TagsApi.fetchCategories(entity),
    payload: { entity },
  };
}

export function saveMembersTag({ userId, tagName, oldTagName }){
  return {
    type: PROFILE_SAVE_MEMBERS_TAGS,
    promise: TagsApi.saveMembersTag({ userId, tagName, oldTagName }),
    payload: { userId, tagName, oldTagName },
  };
}

export function getMembersTags(){
  return {
    type: PROFILE_GET_MEMBERS_TAGS,
    promise: TagsApi.getMembersTags(),
    payload: { },
  };
}

export function getAccountFilters(token){
  return async (dispatch, getState) => {
    const state = getState();
    const user = state.login.user;
    if(!user && !token) {
      return Promise.resolve(); ;
    }
      return await dispatch({
        type: PROFILE_GET_LAST_FILTERS,
        promise: User.getAccountFilters(token),
        errorMessage: 'Failed to load filters',
      });
  };
}

export function saveLastFilters(filter){
  return {
    type: PROFILE_SAVE_LAST_FILTERS,
    promise: User.saveAccountFilters(filter),
    payload: { filter },
    errorMessage: 'Failed to save filters',
  };
}

