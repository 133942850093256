import PropTypes from 'prop-types';
import * as React from 'react';
import cx from 'classnames';

import MenuItem from 'material-ui/MenuItem';
import FormsySelect from '../../Common/Select';
import FormsyDate from '../../Common/FormsyDate';
import PortAutocomplete from '../../Common/PortAutocomplete';
import FormsyText from '../../Common/FormsyText';
import FormsyCheckbox from '../../Common/CheckBox';
import AddIcon from 'material-ui/svg-icons/content/add';
import ButtonForViewRegion from './ButtonForViewRegion';

import FilterWrapper from './FilterWrapper';
import {
  commonAutocompleteHandler,
  commonDependantChange,
  commonGenericChange,
  commonReset,
  commonSelectField,
  DistanceUnitSelect,
  makeNumberIfValid,
  MAX_PORTS_FOR_FILTER,
  prepareVesselFilterFromServer,
  prepareVesselFiltersFromServer,
  prepareVesselFilterToServer,
  SelectedFlags,
  SelectedPorts,
  SelectedTypeByPurposes,
  styleForm,
  Tooltip,
  typeByConstructionList,
  specialties,
} from './common';
import Vessel from '../../../core/api/Vessel';
import s from './Filter.scss';
import FormsyInput from '../../Common/FormsyInput';
import CountrySelect from '../../Common/CountrySelect';
import { TypeByPurposeSelect } from './TypeByPurposeSelect';
import StyledToggle from '../../Common/StyledToggle';
import SharedVesselFilterPart from "./SharedVesselFilterPart";
import MultiCheckbox from '../../Common/MultiCheckbox';
import CustomCheckbox from '../../Common/CustomCheckbox';

const PORT_AUTOCOMPLETE_POPOVER_PROPS = { style: { minWidth: '256px' } };
export const bySpecialtyStyles = {
  hintStyle: {
     top: "14px",
     color: "#0000004D",
     fontSize: "13px",
  },
  underlineStyle: {
    top: "37px",
  },
  style: {
    minWidth: '112px',
    maxWidth: "112px",
    paddingLeft: "0",
    marginLeft: "14px",
    height: "48px",
    maxHeight: "48px",
  },
  iconStyle: {
    opacity: "0.5",
  },
};

export default class VesselFilter extends React.Component {

  static contextTypes = {
    countries: PropTypes.array,
  };

  static defaultProps = {
    openPortsAvailable: true,
  }

  state = {
    specialtyList: [],
    currentSpecialties: [],
  }

  static type = 'vessel';

  static dataFieldName = 'deckVesselFilter';

  getDefaultFilter = () => {
    return {
      "filterName": "",
      "_flagExclude": false,
      "currentPosition": "",
      "openPortsCheckbox": false,
      "openPortsMaxDistance": "",
      "portDestinationMaxDistance": "",
      "openPortsMaxDistanceFieldName": "maxTime",
      "openPorts": [],
      "openPort": "",
      "openFromCheckbox": false,
      "openFrom": null,
      "openUntil": null,
      "typeCheckbox": false,
      "type": undefined,
      "minDWTCheckbox": false,
      "minDWT": "",
      "maxDWT": "",
      "gear": undefined,
      "_flag": "",
      "currentPositionsCheckbox": false,
      "currentPositionsMaxDistance": "",
      "currentPositionsMaxDistanceFieldName": "maxTime",
      "currentPositions": [],
      "destination": "",
      "portDestinationCheckbox": false,
      "portDestination": [],
      "maxLoa":"",
      "maxDraft":"",
      "maxPositionAge":"",
      "maxAge":"",
      "minAge":"",
      "maxBeam":"",
      "minSpeed":"",
      "flags":[],
      "typeByPurposes": [],
      "typeBySpecialty": [],
      "typeByConstruction": undefined,
      "useViableVessels": false,
      "minHoldLength": "",
      "imoFitted": false,
      "itfFitted": false,
      "iceClass": false,
      "openHatch": false,
      "boxShaped": false,
      "maxBallastDays": 0,
      "minGearCapacity": "",
    };
  }

  renderVesselFilter = (tabId, filter, { onChange, removeFromPorts, handleToggleDialog, subscribeOnSubmit, getForm, removeFromArray }) => {
    const now = new Date();
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 10);
    let flag = filter._flag || "";
    const typeByPurpose = filter.typeByPurpose || '';
    let openPortsMaxDistanceFieldName = filter?.openPortsMaxDistanceFieldName && filter.openPortsMaxDistanceFieldName?.length > 0 ? filter.openPortsMaxDistanceFieldName : "maxTime";
    let currentPositionsMaxDistanceFieldName = filter?.currentPositionsMaxDistanceFieldName && filter.currentPositionsMaxDistanceFieldName?.length > 0 ? filter.currentPositionsMaxDistanceFieldName : "maxTime";
    if (filter?._flag) {
      const {_id} = filter._flag;
      flag = this.context.countries.find(country => country._id === _id) || "";
    }
    //const handleGenericChange = (name) => (e, value) => onChange(name, value);
    const handleSelectField = commonSelectField(onChange);
    const handleDependentChange = commonDependantChange(onChange, filter);
    const handleGenericChange = commonGenericChange(onChange, filter);
    const reset = commonReset(onChange);
    const handlePortChange = (name, inputName) => {
      return () => {
        const portSelector = this[`${name}Ref`];
        const distField = this[`${name}MaxDistanceRef`];
        const maxDistance = (distField && distField.getValue()) || filter[`${name}MaxDistance`] || "";
        const port = portSelector?.getValue();
        const formValues = getForm().getModel();
        const maxDistanceFieldName = formValues[`${name}MaxDistanceFieldName`] || 'maxDistance'
        const timeField = this.portDestinationMaxDistanceRef;
        if (typeof port !== 'object' && port !== null) {
          return;
        }

        if(!["currentPosition", "portDestination"].includes(name)) {
          portSelector.resetValue();
        }
        distField && distField.setValue(""); // resetValue leads to inconsistent view state
        timeField && timeField.setValue("");

        let onChangeObj = {
          ...port,
          [maxDistanceFieldName]:maxDistance,
        };

        onChange(name, onChangeObj);
        if (inputName) {
          reset(inputName);
        }
        reset(`${name}MaxDistance`);
      };
    };

    const handleRemoveByPurpose = (type) => {
      const newTypes = filter.typeByPurposes.filter(tbp => tbp !== type);
      onChange('typeByPurposes', newTypes, false);
      if (filter.typeBySpecialty && filter.typeBySpecialty.length) {
        const newSpecialty = filter.typeBySpecialty?.filter(spec => spec.startsWith("Without") ? specialties[type].every(item => item?.value !== spec) : !specialties[type].includes(spec));
        onChange('typeBySpecialty', newSpecialty);
      }
      if (!newTypes.length) {
        onChange('typeByPurposesCheckbox', false);
      }
    };
    const handleAddByPurpose = () => {
      const form = getForm();
      const model = form.getModel();
      if (!model.typeByPurpose){
        return;
      }
      const type = model.typeByPurpose;
      const specialty = this.state.currentSpecialties;

      const newTypes = filter.typeByPurposes || [];
      if (!newTypes.find(t => t === type)) {
        newTypes.push(type);
      }

      let newSpecialty = filter.typeBySpecialty || [];
      if (newTypes.find(t => t === type) && !!specialty) {
        newSpecialty = newSpecialty.filter(spec => spec.startsWith("Without") ? specialties[type].every(item => item?.value !== spec) : !specialties[type].includes(spec));
        newSpecialty.push(...specialty);
      }
      onChange('typeBySpecialty', newSpecialty);
      onChange('typeByPurposes', newTypes);
      onChange('typeByPurpose', "");
      this.setState({ specialtyList: [] });
      onChange('typeByPurposesCheckbox', true);
   };

  const handleBySpecialtyChange = (specialty) => {
    this.setState({ currentSpecialties: specialty });
  };
  const handleByPurposeChange = (e, type) => {
    onChange('typeByPurpose', type, false);
    onChange('typeByPurposesCheckbox', true);
    this.setState({ specialtyList: specialties[type], currentSpecialties: filter.typeBySpecialty?.filter(spec => specialties[type].includes(spec) || specialties[type].some(item => item.value === spec)) });
  };

  const checkboxPopoverRenderer = (status, index) => (
    <MenuItem style={{ zIndex: `${status.rightIcon ? "2" : "1"}`, overflow: "visible" }} innerDivStyle={{ display: "flex", alignItems: "center", height: "34px" }} key={status.id} value={status}>
      <CustomCheckbox disabled={status.disabled || false} checked={status.checked} onCheck={(ev, val) => !status.disabled && status.onChange(val)} labelPosition={status.labelPosition || "left"} label={status.name} />
        <div>
          {status.rightIcon}
        </div>

    </MenuItem>
  );

    subscribeOnSubmit(() => {
      if (filter.openPortsCheckbox) {
        handlePortChange("openPorts", 'openPort')();
        reset("openPort");
      }
      if (filter.currentPositionsCheckbox) {
        handlePortChange("currentPositions", "currentPosition")();
        reset("currentPosition");
      }
      if (filter.portDestinationCheckbox) {
        handlePortChange("portDestination", "destination")();
        reset("destination");
      }
      if (filter.typeByPurposesCheckbox) {
        handleAddByPurpose();
        this.setState({ specialtyList: [] });
        reset("typeByPurpose");
      }
      if (filter.flagsCheckbox && typeof filter._flag === 'object' && filter._flag !== null) {
        onChange('flags', {
          country: {
            ...(filter._flag)
          },
          exclude: !!filter._flagExclude
        });
        reset("_flag");
      }
    });
    const autocompleteHandler = commonAutocompleteHandler.call(this, onChange);
    const openPortsAvailable = this.props.openPortsAvailable;
    return (
      <span key={tabId} className={cx(s.body_filter, filter.useViableVessels && s.useViableVessels)}>

        <div className="row align-items-center">
            <div className="col-4">
              <p className={s.title_filter_name}>Filter name:</p>
            </div>
            <div className={cx("col-3 max-h-42", s.pr0)} style={{ minWidth: "118px" }}>
              <FormsyText
                name="filterName"
                hintText="Enter filter name"
                className={s.input}
                fullWidth
                value={filter.filterName}
                onChange={handleGenericChange('filterName')}
              />
            </div>
            <div className={cx('col grow', s.pr0)}>
              { openPortsAvailable ? <StyledToggle labelPosition={"right"} label={"Potential port arrival"} toggled={filter.useViableVessels} onToggle={handleGenericChange('useViableVessels')} /> : null}
          </div>
          </div>
        { openPortsAvailable ? (
          <span>
            <div className="row align-items-center port-of-region">
              <div className={cx("col-4", s.checkbox_wrapper)}>
                <FormsyCheckbox
                  label={filter.useViableVessels ? "Place:" : "Open place:"}
                  name="openPortsCheckbox"
                  ref="openPortsCheckbox"
                  onChange={handleGenericChange('openPortsCheckbox')}
                  className={s.checkbox}
                  value={filter.openPortsCheckbox}
                  labelStyle={styleForm.labelStyle}
                />
              </div>
              <div className={cx("col grow max-h-42", s.wrapper_add_port, s.open_place_wrapper)}>
                <PortAutocomplete
                  sendOnlyObjectValue
                  forceSelection={false}
                  ref={ref => this.openPortsRef = ref}
                  name="openPort"
                  hintText="Port or region"
                  className={s.input}
                  value={filter.openPort}
                  params={{ withAreas: true }}
                  disabled={ filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER }
                  onNewRequest={autocompleteHandler('openPort', 'openPorts')}
                  popoverProps={PORT_AUTOCOMPLETE_POPOVER_PROPS}
                  updateInput={handleGenericChange('openPort', 'openPorts', false).bind(this, null)}
                />
                <div className={s.wrapper_dop_info}>
                   {!filter.useViableVessels ? [<div className={s.plus_days} >
                     <FormsyText
                       ref={ref => this.openPortsMaxDistanceRef = ref}
                       hintText="+ "
                       name="openPortsMaxDistance"
                       fullWidth
                       value={filter.openPortsMaxDistance}
                       // had to use this blur handler to get int value instead of string
                       onBlur={handleGenericChange('openPortsMaxDistance', null, false, null)}
                       validations="isNumeric,gt0"
                       validationError="invalid"
                       className={cx(s.input)}
                       disabled={ filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER }
                     />
                   </div>,
                     <DistanceUnitSelect
                       name="openPortsMaxDistanceFieldName"
                       value={openPortsMaxDistanceFieldName}
                       onChange={handleGenericChange('openPortsMaxDistanceFieldName', null, false, null)}
                       disabled={ filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER}
                     />] : null}
                  <ButtonForViewRegion
                    handleToggleDialog={() => handleToggleDialog('openPorts')}
                    disabled={filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER}
                  />
                  <Tooltip disabled={filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER} text="Add">
                    <AddIcon
                      className={cx(s.btn_add_port_region, s.icon, {[s.btn_add_port_region_disabled]: filter.openPorts && filter.openPorts.length === MAX_PORTS_FOR_FILTER})}
                      onClick={handlePortChange('openPorts', 'openPort')}
                    />
                  </Tooltip>
                </div>

              </div>

            </div>

            <SelectedPorts
              ports={filter.openPorts}
              removeFromPorts={(port) => removeFromPorts('openPorts', port)}
              distanceUnit="nm"
              hideDistance={filter.useViableVessels}
            />

            <div className="row align-items-center">
              <div className={cx("col-4", s.checkbox_wrapper)}>
                <FormsyCheckbox
                  label={filter.useViableVessels ? "ETA day:" : "Open date:"}
                  name="openFromCheckbox"
                  ref="openFromCheckbox"
                  className={s.checkbox}
                  onChange={handleGenericChange('openFromCheckbox')}
                  value={filter.openFromCheckbox}
                  labelStyle={styleForm.labelStyle}
                />
              </div>
              <div className="col align-items-center flex max-h-42 space_children12">
                  <FormsyDate
                    fullWidth
                    name="openFrom"
                    ref="openFrom"
                    container="inline"
                    hintText="From"
                    className={s.input}
                    autoOk
                    onChange={handleGenericChange('openFrom')}
                    minDate={minDate}
                    maxDate={filter.openUntil}
                    value={filter.openFrom}
                    /*style={{height:'42px'}}
                    textFieldStyle={{height:'42px', fontSize:'13px'}}*/
                    clearable
                  />
                  <FormsyDate
                    fullWidth
                    name="openUntil"
                    ref="openUntil"
                    minDate={filter.openFrom || minDate}
                    startDate={filter.openFrom || now}
                    container="inline"
                    hintText="To"
                    className={s.input}
                    autoOk
                    /*style={{height:'42px'}}
                    textFieldStyle={{height:'42px', fontSize:'13px'}}*/
                    onChange={handleDependentChange('openUntil', 'openFromCheckbox')}
                    value={filter.openUntil}
                    clearable
                  />
              </div>
            </div>


            </span>
        ) : null }
            <div className="row align-items-center port-of-region">
              <div className={cx("col-4", s.checkbox_wrapper)}>
                <FormsyCheckbox
                  label="Current:"
                  name="currentPositionsCheckbox"
                  ref="currentPositionsCheckbox"
                  onChange={handleGenericChange('currentPositionsCheckbox')}
                  className={s.checkbox}
                  value={filter.currentPositionsCheckbox}
                  labelStyle={styleForm.labelStyle}
                />
              </div>
              <div className={cx("col grow max-h-42", s.wrapper_current)}>
                <PortAutocomplete
                  sendOnlyObjectValue
                  forceSelection={false}
                  ref={ref => this.currentPositionsRef = ref}
                  name="currentPosition"
                  value={filter.currentPosition}
                  hintText="Port or region"
                  className={s.input}
                  fullWidth
                  params={{ withAreas: true }}
                  onNewRequest={autocompleteHandler('currentPosition', 'currentPositions')}
                  popoverProps={PORT_AUTOCOMPLETE_POPOVER_PROPS}
                  disabled={ filter.currentPositions && filter.currentPositions.length === MAX_PORTS_FOR_FILTER }
                  updateInput={handleGenericChange('currentPosition', 'currentPositions', false).bind(this, null)}
                />
                <div className={s.wrapper_dop_info} style={{paddingRight: "26px"}}>
                  <ButtonForViewRegion
                    handleToggleDialog={() => handleToggleDialog('currentPositions')}
                    disabled={filter.currentPositions && filter.currentPositions.length === MAX_PORTS_FOR_FILTER}
                  />
                </div>
              </div>

          </div>
          <SelectedPorts
            ports={filter.currentPositions}
            removeFromPorts={(port) => removeFromPorts('currentPositions', port)}
          />
          <FormsyInput type="hidden" name="portDestinationMaxDistanceFieldName" value="maxTime" />
          <div className="row align-items-center port-of-region">
            <div className={cx("col-4", s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="Destination:"
                name="portDestinationCheckbox"
                ref="portDestinationCheckbox"
                onChange={handleGenericChange('portDestinationCheckbox')}
                className={s.checkbox}
                value={filter.portDestinationCheckbox}
                labelStyle={styleForm.labelStyle}
              />
            </div>
            <div className={cx("col grow max-h-42", s.wrapper_current_destination)}>
              <PortAutocomplete
                sendOnlyObjectValue
                forceSelection={false}
                ref={ref => this.portDestinationRef = ref}
                name="portDestination"
                hintText="Port or region"
                className={s.input}
                value={filter.destination}
                params={{ withAreas: true }}
                onNewRequest={autocompleteHandler('destination', 'portDestination')}
                popoverProps={PORT_AUTOCOMPLETE_POPOVER_PROPS}
                updateInput={handleGenericChange('destination', 'portDestination', false).bind(this, null)}
                disabled={filter.portDestination && filter.portDestination.length === MAX_PORTS_FOR_FILTER}
              />
              <div className={s.wrapper_dop_info}>
                <FormsyText
                  ref={ref => this.portDestinationMaxDistanceRef = ref}
                  hintText="Days"
                  name="portDestinationMaxDistance"
                  value={filter.portDestinationMaxDistance}
                  onBlur={handleGenericChange('portDestinationMaxDistance', null, false, null)}
                  fullWidth
                  validations="isNumeric,gt0"
                  validationError="invalid"
                  className={s.input}
                  style={{
                    width: '66px',
                  }}
                  disabled={filter.portDestination && filter.portDestination.length === MAX_PORTS_FOR_FILTER}
                />
                <ButtonForViewRegion
                  handleToggleDialog={() => handleToggleDialog('portDestination')}
                  disabled={filter.portDestination && filter.portDestination.length === MAX_PORTS_FOR_FILTER}
                />
                <Tooltip disabled={filter.portDestination && filter.portDestination.length === MAX_PORTS_FOR_FILTER} text="Add">
                  <AddIcon
                    className={cx(s.btn_add_port_region, s.icon, {[s.btn_add_port_region_disabled]: filter.portDestination && filter.portDestination.length === MAX_PORTS_FOR_FILTER})}
                    onClick={handlePortChange('portDestination', 'destination')}
                  />
                </Tooltip>
              </div>
            </div>

          </div>
          <SelectedPorts
            ports={filter.portDestination}
            removeFromPorts={(port) => removeFromPorts('portDestination', port)}
          />

          <div className="row align-items-center port-of-region">
            <div className={cx("col-4", s.checkbox_wrapper)}>
              <FormsyCheckbox
                label="Flag:"
                name="flagsCheckbox"
                ref="flagsCheckbox"
                onChange={handleGenericChange('flagsCheckbox')}
                className={s.checkbox}
                value={filter.flagsCheckbox}
                labelStyle={styleForm.labelStyle}
              />
            </div>
            <div className={cx("col grow max-h-42", s.wrapper_add_port, s.add_flag_wrapper)}>
              <CountrySelect
                name="_flag[country]"
                ref="_flag[country]"
                hintText="Country"
                valueSelector={(c)=>c}
                fullWidth
                value={flag}
                onChange={handleGenericChange("_flag", "flags", false)}
                withUnions
              />
              <div className={s.wrapper_dop_info}>
                 <FormsyCheckbox
                   label="Except"
                   name="_flag[exclude]"
                   className={s.checkbox}
                   value={filter._flagExclude}
                   labelStyle={styleForm.labelStyle}
                   onChange={handleGenericChange('_flagExclude', null, false)}
                 />
                 <Tooltip noMargin text="Add">
                  <AddIcon
                    className={cx(s.btn_add_port_region, s.icon)}
                    onClick={()=>{
                      const form = getForm()
                      let model = form.getModel();
                      if(!model._flag?.country){
                        return;
                      }
                      onChange('flags', model._flag);
                      (form.inputs.find(input => input.props.name === '_flag[country]')).resetValue();
                    }}
                  />
                 </Tooltip>


              </div>

            </div>

          </div>
          <SelectedFlags
            flags={filter.flags}
            remove={(flag) => removeFromArray('flags', flag)}
          />
           <div className={cx("row align-items-center ", s.type_row) }>
             <div className={cx("col-4", s.checkbox_wrapper)}>
               <FormsyCheckbox
                 label="By purpose:"
                 name="typeByPurposesCheckbox"
                 ref="typeByPurposesCheckbox"
                 className={s.checkbox}
                 onChange={handleGenericChange('typeByPurposesCheckbox')}
                 value={filter.typeByPurposesCheckbox}
                 labelStyle={styleForm.labelStyle}
               />
             </div>
              <div className="col align-items-center flex max-h-42 space_children12">
                  <TypeByPurposeSelect onChange={handleByPurposeChange} value={typeByPurpose} />
                  <MultiCheckbox
                    floatingLabelText=""
                    hintText="By specialty"
                    hintStyle={bySpecialtyStyles.hintStyle}
                    underlineStyle={bySpecialtyStyles.underlineStyle}
                    style={bySpecialtyStyles.style}
                    iconStyle={bySpecialtyStyles.iconStyle}
                    name="typeBySpecialty"
                    list={this.state.specialtyList}
                    value={filter.typeByPurpose ? this.state.currentSpecialties : null}
                    disabled={!this.state.specialtyList.length}
                    onChange={handleBySpecialtyChange}
                    popoverProps={{
                      style: {
                        width: "260px",
                        overflow: "scroll",
                      },
                      renderer: checkboxPopoverRenderer,
                    }}
                  />
                  <div className={cx(s.wrapper_dop_info)}>
                    <Tooltip noMargin text="Add">
                      <AddIcon
                        className={cx(s.btn_add_port_region, s.icon)}
                        onClick={handleAddByPurpose}
                      />
                    </Tooltip>
                  </div>
              </div>
           </div>

          <SelectedTypeByPurposes
            selectedByPurpose={typeByPurpose}
            list={filter.typeByPurposes}
            specialtyList={filter.typeBySpecialty}
            handleRemove={(tbp) => handleRemoveByPurpose(tbp)}
          />

        <div className={cx("row align-items-center ", s.type_row) }>
             <div className={cx("col-4", s.checkbox_wrapper)}>
               <FormsyCheckbox
                 label="By construction:"
                 name="typeByConstructionCheckbox"
                 ref="typeByConstructionCheckbox"
                 className={s.checkbox}
                 onChange={handleGenericChange('typeByConstructionCheckbox')}
                 value={filter.typeByConstructionCheckbox}
                 labelStyle={styleForm.labelStyle}
               />
             </div>
              <div className="col align-items-center flex max-h-42 space_children12">
                 <FormsySelect
                   name="typeByConstruction"
                   fullWidth
                   ref="typeByConstruction"
                   className={s.select}
                   onChange={handleGenericChange('typeByConstruction')}
                   hintText=" "
                   value={filter.typeByConstruction}
                   style={{minWidth: '133px'}}
                 >
                   {typeByConstructionList.map(t=> <MenuItem key={t} value={t} primaryText={t || 'All'} /> )}
                 </FormsySelect>
              </div>
           </div>


             <div>
               <SharedVesselFilterPart
                 filter={filter}
                 handleGenericChange={handleGenericChange}
                 handleDependentChange={handleDependentChange}
                 handleSelectField={handleSelectField}
               />
             </div>
      </span>
    );
  }

  fetchData = async () => {
    try {
    const response = await Vessel.getVesselFilters();
    const filters = response.data;
    return prepareVesselFiltersFromServer(filters, this.constructor.dataFieldName);
    } catch (error) {
      console.log(error);
    }
  }

  saveFilterToDB = async (filterName, filterData) => {
    try {
    const response = await Vessel
      .saveVesselFilter(filterName, prepareVesselFilterToServer(filterData));
      prepareVesselFilterFromServer(response.data);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  updateFilterInDB = async (tabId, formData) => {
    try {
    const response = await Vessel
      .updateVesselFilter(tabId, prepareVesselFilterToServer(formData));
      prepareVesselFilterFromServer(response.data);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  deleteFilter = (tabId) => {
    return Vessel
      .deleteVesselFilter(tabId)
      .catch(error => console.error(error));
  }

  updatePorts = ({ name, value, ports, updateFilterField }) => {
    const newPort = {
      _id: value._id,
      name: value.name,
      areaParent: value.areaParent,
      maxDistance: value.maxDistance || 0,
      maxTime: value.maxTime || 0,
      exclude: value.exclude,
    }

    const samePort = ports.find((port) => port._id === value._id);

    let nextPorts
    if (!samePort) {
      if (ports.length >= MAX_PORTS_FOR_FILTER) {
        return;
      }

      nextPorts = [...ports, newPort]
    } else {
      nextPorts = ports.map(port => port === samePort ? newPort : port);
    }

    updateFilterField(name, nextPorts);
    updateFilterField(`${name}Checkbox`, nextPorts.length > 0);
  }

  render() {

    const { type = 'vessel' } = this.constructor;
    const {
      fetchData = this.fetchData,
      saveFilterToDB = this.saveFilterToDB,
      updateFilterInDB = this.updateFilterInDB,
      deleteFilter = this.deleteFilter,
      dataFieldName = (this.constructor.dataFieldName || 'deckVesselFilter'),
      ...props
    } = this.props;
    return (
      <FilterWrapper
        {...props}
        dataFieldName={dataFieldName}
        renderFilter={this.renderVesselFilter}
        handleFilter={this.props.handleFilter}
        defaultFilter={this.getDefaultFilter}
        updatePorts={this.updatePorts}
        fetchData={fetchData}
        saveFilterToDB={saveFilterToDB}
        updateFilterInDB={updateFilterInDB}
        deleteFilter={deleteFilter}
        type={type}
        user={this.props.user}
        isDark={this.props.isDark}
        popoverProps={{
          targetOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          canAutoPosition: true
        }}
      />
    )
  }
}
