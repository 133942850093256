import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import styled from "styled-components";

const style = { display: "inline-block" };

const Tooltip = styled.div`
  > div {
    background: ${props =>
      props.theme.name !== "dark_theme"
        ? "var(--bg-table-dark)"
        : "#FFF"} !important;
    color: ${props =>
      props.theme.name !== "dark_theme" ? "#FFF" : "#333"} !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 12px;
    white-space: normal;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    text-transform: initial;
    margin: 0px;
    .top_loading {
      border-bottom: 1px solid ${props =>
        props.theme.name !== "dark_theme" ? "rgba(#FFF, 0.2)" : "var(--stroke-light-gray2)"} !important;
      & > div {
        p {
          &:first-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme" ? "var(--text-links-light)" : "var(--text-links)"} !important;
              }
              .tag_name {
                color: ${props => props.theme.name === 'dark_theme' ? 'var(--text-medium)' : 'var(--text-links-light)'} !important;
              }
            }
          }
          &:last-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme" ? "#FFF" : "#000"};
              }
              &:last-child {
                color: ${props =>
                  props.theme.name !== "dark_theme" ? "rgba(#FFF, 0.7)" : "var(--text-medium)"} !important;
              }
            }
          }
        }
      }
    }
    .bottom_discharging {
      & > div {
        p {
          &:first-child {
            span {
              &:first-child {
                color: ${props =>
                  props.theme.name !== "dark_theme" ? "var(--text-green-light)" : "var(--text-green-dark)"} !important;  ;
              }
            }
          }
          &:last-child {
            span {
              color: ${props =>
                props.theme.name !== "dark_theme" ? "rgba(#FFF, 0.7)" : "var(--text-medium)"} !important;;
            }
          }
        }
      }
    }
  }
  p {
    color: ${props =>
      props.theme.name !== "dark_theme" ? "#FFF" : "#333"} !important;
  }

  &.top_arrow_tooltip {
    &:before {
      border-bottom: 4px solid ${props =>
        props.theme.name !== "dark_theme" ? "var(--bg-table-dark)" : "#FFF"};
    }
  }
  &.bottom_arrow_tooltip {
    &:before {
      border-bottom: 4px solid transparent;
      border-top: 4px solid ${props =>
        props.theme.name !== "dark_theme" ?"var(--bg-table-dark)" : "#FFF"};
    }
  }
`;

export class WithTooltip extends PureComponent {
  static propTypes = {
    showOnEventName: PropTypes.oneOf(["onMouseOver", "onClick"]),
    offset: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    horizontalPosition: PropTypes.oneOf(["left", "right"]),
    closeOnChildrenClick: PropTypes.bool,
    positionInsideTransform: PropTypes.bool,
    bottomArrow: PropTypes.bool,
    position: PropTypes.oneOf(["absolute", "fixed"]),
    noArrow: PropTypes.bool,
    tooltipStyle: PropTypes.object
  };

  static defaultProps = {
    showOnEventName: "onMouseOver",
    offset: { x: 0, y: 0 },
    horizontalPosition: "right",
    closeOnChildrenClick: false,
    positionInsideTransform: false,
    bottomArrow: false,
    position: "fixed",
    noArrow: false
  };

  constructor(props) {
    super(props);
    this.state = {
      coords: null,
      anchor: null,
      className: "",
      styleTop: null
    };
    this.tooltipContainer = null;
  }

  componentWillUnmount() {
    this.removeListeners();
    if (this.tooltipContainer && document) {
      document.body.removeChild(this.tooltipContainer);
    }
  }

  removeListeners = () => {
    if (window) {
      window.removeEventListener("scroll", this.handleWindowScroll);
      window.removeEventListener("resize", this.handleWindowScroll);
    }
    if (document) {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  };

  handleClickOutside = event => {
    if (
      this.tooltipContainer &&
      !this.tooltipContainer.contains(event.target) &&
      this.state.anchor &&
      !this.state.anchor.contains(event.target)
    ) {
      this.handleClose();
    }
  };
  handleLeave = () => {
    if (this.props.showOnEventName === "onMouseOver") {
      this.handleClose();
    }
  };
  handleClose = () => {
    this.setState({ coords: null, anchor: null });
    this.removeListeners();
    clearTimeout(this.openTimeout);
    this.props.onClose && this.props.onClose();
    if (this.tooltipContainer && document) {
      document.body.removeChild(this.tooltipContainer);
      this.tooltipContainer = null;
    }
  };

  handleOver = e => {
    if (!this.props.tip) return;

    if (e) {
      if (this.props.showOnEventName === "onClick") {
        e.stopPropagation();
        e.preventDefault();
      }

      if (this.state.coords && this.props.showOnEventName === "onClick") {
        return this.handleClose();
      }

      e.stopPropagation();
      clearTimeout(this.closeTimer);
      if (window) {
        window.addEventListener("scroll", this.handleWindowScroll);
        window.addEventListener("resize", this.handleWindowScroll);
      }
      if (this.props.showOnEventName === "onClick" && document) {
        document.addEventListener("mousedown", this.handleClickOutside);
      }
    }

    const anchor = this.state.anchor || (e && e.currentTarget);
    const rect = anchor?.getBoundingClientRect() || { x: 0, y: 0, height: 0 };
    const coords = {
      x: rect.x + (this.props.offset.x || 0),
      y: rect.y + rect.height + (this.props.offset.y || 0)
    };

    this.setState({ coords, anchor });
    this.props.onOpen && this.props.onOpen();

    if (!this.tooltipContainer && document) {
      this.tooltipContainer = document.createElement("div");
      document.body.appendChild(this.tooltipContainer);
    }
  };

  handleWindowScroll = () => {
    this.handleOver();
  };

  renderTooltip() {
    if (!this.state.coords || !this.props.tip) return null;

    const {
      tip,
      tooltipStyle,
      position,
      bottomArrow,
      noArrow,
      horizontalPosition
    } = this.props;

    const tooltipContent =
      typeof tip === "function"
        ? tip({ handleClose: this.handleClose, reposition: this.handleOver })
        : tip;

    return ReactDOM.createPortal(
      <Tooltip
        ref="spanTooltip"
        className={cx(
          "arrow_tooltip",
          !bottomArrow && "top_arrow_tooltip",
          bottomArrow && "bottom_arrow_tooltip",
          noArrow && "no_arrow",
          this.props.whiteArrow && "white_arrow",
          horizontalPosition === "left" && "right_arrow_tooltip",
          this.state.className
        )}
        style={{
          position,
          left: this.state.coords.x,
          top: this.state.coords.y,
          zIndex: 10000,
          ...tooltipStyle
        }}
        onClick={e => e.stopPropagation()}
      >
        {tooltipContent}
      </Tooltip>,
      this.tooltipContainer
    );
  }

  render() {
    const { style: customStyle, className, children } = this.props;
    return (
      <div
        style={{ ...style, ...customStyle }}
        className={className}
        onMouseLeave={this.handleLeave}
        onMouseDown={this.handleClickOutside}
        {...{ [this.props.showOnEventName]: this.handleOver }}
      >
        {children}
        {this.renderTooltip()}
      </div>
    );
  }
}
