import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dialog from '../Common/Dialog';
import ButtonForm from './ButtonForm';
import { MenuItem } from 'material-ui/Menu/index';
import FormsyText from '../Common/FormsyText';
import Restrictions from './Restrictions';
import { Email, Phone } from '../Settings/spEdit';
import s from './Port.scss';
import sg from './General.scss';
import Formy from 'formsy-react';
import cx from 'classnames';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import ActionRestore from 'material-ui/svg-icons/action/settings-backup-restore';
import { sortObject } from '../../core/utils';


import {
  floatingLabelStyle,
  floatingLabelFocusStyle,
  floatingLabelShrinkStyle,
  inputStyle,
  CRANE_GROUPS,
  availebleLiftCranes,
  WAREHOUSES,
  availableFacilities,
  restrictions,
  restrictionsService,
  serviceProviders,
  timezoneList,
  ACTIVITIES,
} from './settings';
import RaisedButton from '../Common/RaisedButton';
import Select from '../Common/Select';
import { removeFromArray, replaceInArray } from '../../core/utils';
import Collapse from '../Common/Collapse';
import AddCapacity from '../Icons/AddCapacity';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

const Form = Formy.Form;

const labelStyle = { fontSize: '12px', lineHeight: '12px', color: '#999999', top: '46px', transform: 'scale(1) translate(0px, 0px)' };
const labelShrinkStyle = { transform: 'scale(1) translate(0px, -20px)' };

const compileSingleProvider = (provider, originProviders) => {
    if (provider.history) {
      provider = {...provider, _id: provider._id, activities: provider.activities, ...provider.history[provider.history.length - 1].changedValues };
    }
    provider = provider ? { ...provider, limitations: provider.limitations?.length && provider.limitations.some(lim => !!lim) ? provider.limitations : [{}] } : {_id: Math.random(), activities: [''], name: '', address: '', PIC: '', emails: [], phones: [], limitations: [{}] };;
    let dirty = false;
    let origin = originProviders.find(op => op._id === provider._id);
    let status = provider.status;
    if (!origin) {
      origin = { activities: [''], name: '', address: '', PIC: '', emails: [], phones: [], limitations: [{}] };
      // status = 'new';
    }

    if (!origin.limitations.length) origin.limitations = [{}];

    if (JSON.stringify(provider.phones.map(({formatted, ...rest}) => rest)) !== JSON.stringify(origin.phones)) dirty = true;
    if (JSON.stringify(provider.emails) !== JSON.stringify(origin.emails)) dirty = true;

    provider.limitations = provider.limitations.length >= 0 ? provider.limitations : [{}];
    const ol = origin.limitations || [{}];

    if (provider?.limitations?.length) {
      provider.limitations = provider.limitations.map((lim, index) => {
        const shouldSave = restrictionsService.find(({name, isString}) => !!lim[name]);
        if (lim.new && !shouldSave) return;
        if (lim.new) dirty = true;
        delete lim.new;
        const newRest = restrictionsService.map(({name, isString}) => {
          let olLim;
          if (lim._id) {
            const existedValue = ol.find(({_id}) => _id === lim._id )?.[name];
            if (existedValue) {
              olLim = existedValue;
            } else {
              if (lim?.[name]) {
                olLim = "previousValue"
              } else {
                olLim = undefined
              }
            }
          } else {
            if (lim?.[name]) {
              olLim = "previousValue"
            } else {
              olLim = undefined
            }
          }
          const newLim = lim?.[name] || undefined;
          const changed = newLim != olLim;
          if (changed && provider.activities.includes('Stevedoring Company')) dirty = true;

          return [
            name,
            {
              value: typeof lim[name] === 'object' ? lim[name].toJSON() : newLim,
              isString,
              changed,
              previousValue: olLim,
              valueOf: function () {
                return this.value
              },
              toString: function () {
                return this.value
              },
              toJSON: function () {
                return this.isString ? this.toString() : this.valueOf();
              }
            }
          ]
        });
        return Object.fromEntries(
          [
            ...Object.entries(lim),
            ["removed", false],
            ["_id", lim._id],
            ...newRest
          ]
        )
      });
    }

    provider.limitations = provider.limitations.filter(item => !!item);
    if (ol.length !== provider.limitations.length) dirty = true;

    provider.activities = provider.activities.filter((item, index, array) =>
      array.findIndex(temp => (temp?.toString() === item?.toString())) === index)

	if (origin.activities.length !== provider.activities.length) dirty = true;
    provider.activities = provider?.activities?.map((activity, index) => {
      const value = typeof activity === 'object' ? activity.toString() : activity;
      const changed = origin.activities.length && !origin.activities.includes(value);
      if (changed) dirty = true;
      return {
        removed: false,
        currentValue: value,
        get value() {
          return this.currentValue
        },
        set value(newValue) {
          this.currentValue = newValue;
        },
        changed,
        previousValue: origin.activities?.find(activity => activity === value) || "",
        toString: function () {return this.value},
        toJSON: function() {return this.value}
      }
    });

    if (provider.name !== origin.name || provider.address !== origin.address || provider.PIC !== origin.PIC) {
      dirty = true;
    }
    return { ...provider, origin, status, dirty };
}

const compileOsp = (provider, originProviders) => {
  if (provider.history) {
    provider = {...provider, _id: provider._id, activities: provider.activities, ...provider.history[provider.history.length - 1].changedValues };
  }
  return {...provider}
}



export default class ServiceProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state.osp = compileOsp(props.sp, props.originProviders);
    this.state.sp = compileSingleProvider(props.sp, props.originProviders);
    this.state.open = true;
    this.state.key = Math.random();
    this.state.removed = props.sp.status === 'removed';
    this.state.newLimitation = props.sp.activities.map(activity => !activity?.removed && activity.toString() === 'Stevedoring Company');
    this.state.changeListenReady = false;
    this.state.showRestore = false;
  }

  static propTypes = {
    handleSaveSp: PropTypes.func.isRequired,
    sp: PropTypes.object,
    country: PropTypes.object,
  };

  static defaultProps = {
    sp: { emails: [], phones: [], activities: [] },
  };
  state = {};

  toggleShowRestore = () => {
    const {isSaveReady, status, dirty, mergedEmails, mergedPhones, origin, history, __v, _id, ...sp} = this.state.sp;
    const cleanSP = {
      ...sp,
      phones: sp.phones.map(({status, formatted, ...ph}) => ({...ph})),
      limitations: sp.limitations.map(({customId, removed, ...lim}) => {delete lim.new; return JSON.parse(JSON.stringify(lim))}).filter(item => item && Object.keys(item)?.length),
      activities: sp.activities.map(activity => activity.toString())
    };
    const {history: oHistory, __v : ov, _id: oid, ...osp} = this.state.osp;
    const cleanOSP = {
      ...osp,
      phones: osp.phones.map(({status, formatted, ...ph}) => ({...ph})),
      activities: osp.activities.filter((item, index, array) => array.findIndex(temp => (temp?.toString() === item?.toString())) === index),
      limitations: osp.limitations.map(lim => Object.fromEntries(Object.entries(lim).filter(([key, value]) => !!value)))
    }
    return JSON.stringify(sortObject(cleanOSP)) !== JSON.stringify(sortObject(cleanSP));
  }

  componentDidMount() {
    this.setState({
        newLimitation: this.state.sp.activities.map(activity => !activity?.removed && activity.toString() === 'Stevedoring Company')
      }, () => {
        this.setState({changeListenReady: true});
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.formChanged !== this.state.formChanged) {
      this.props.parentChangeCb(this.state.formChanged);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      removed: nextProps.sp.status === 'removed'
    })
  }

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  handleAddActivity = () => {
    this.setState({
		sp: {
			...this.state.sp,
			activities: [
				...this.state.sp.activities,
				{new: true, previousValue: 'previousValue', toString: function () {return this.value}}
			]
		}
	});
  };

  handleRemoveActivity = (i) => {
    const newActivities = [...this.state.sp.activities];
    newActivities[i].removed = true;
    this.setState({formChanged: true, sp: { ...this.state.sp, activities: newActivities} },
      // () => this.setState({newLimitation: this.state.sp.activities.map(activity => !activity?.removed && activity.toString() === 'Stevedoring Company')})
      () => this.setState({newLimitation: this.state.newLimitation.map((value, index) => index === i ? false : value)})
    );
  };

  handleChangeActivity = (e, activity, index) => {
    const newLimitation = [...this.state.newLimitation];
    newLimitation[index] = activity === 'Stevedoring Company';
    this.setState({newLimitation});
  };

  handleSubmit = (values) => {
    const saveAllowed = this.state.sp.status === "initial" ? {isSaveReady: true} : {};
    const status = this.state.sp.status;
    const saveObj = {...values};
    saveObj._id = this.state.sp._id;
    if (saveObj.limitations) {
      saveObj.limitations = values.limitations.map((lim, index) => ({...this.state.sp.limitations[index], ...lim }));
    }
    this.setState({
      showRestore: true,
      key: Math.random(),
      sp: compileSingleProvider({status, ...saveObj}, this.props.originProviders)
    }, () => this.props.handleSaveSp(null, { ...saveObj, ...saveAllowed }))
  };

  handleChange = () => {
    this.setState({formChanged: true});
    this.props.handleAddConfirmModal();
  }

  handleRestore = () => {
    if (this.state.removed) {
      this.setState({
        formChanged: false,
        removed: false
      }, () => this.props.handleRestore())
    } else {
      this.setState({
        key: Math.random(),
        formChanged: false,
        sp: compileSingleProvider(this.state.osp, this.props.originProviders),
      }, () => {
        this.props.handleSaveSp(null, this.state.osp);
        this.setState({
          showRestore: false,
          newLimitation: this.state.sp.activities.map(activity => !activity?.removed && activity.toString() === 'Stevedoring Company'),
        }, () => this.setState({formChanged: false}))
      })
    }
  }

  handleCancel = () => {
    this.setState({
      showRestore: this.toggleShowRestore(),
      formChanged: false,
      key: Math.random(),
      sp: {
        ...this.state.sp,
        activities: this.state.sp.activities.map(item => ({...item, removed: false})).filter(item => !item.new),
        limitations: this.state.sp.limitations.map(item => ({...item, removed: false})).filter(item => !item.new)
      }
    }, () => this.setState({newLimitation: this.state.sp.activities.map(activity => !activity?.removed && activity.toString() === 'Stevedoring Company')}));
  }

  handleRemoveLimitation = (i) => {
    const newLimitations = [...this.state.sp.limitations];
    newLimitations[i].removed = true;
    this.setState({formChanged: true, sp: { ...this.state.sp, limitations: newLimitations} });
  }

  handleAddLimitation = () => {
    this.setState({sp: {...this.state.sp, limitations: [...this.state.sp.limitations, {new: true, customId: Math.random()}]}})
  }

  handleRemove = () => {
    this.handleRestore();
    this.props.handleRemove();
  }

  render() {
    const sp = this.state.sp;
    const activitiesToRender = sp.activities;

    return (
      <Collapse
          titleClass={this.state.removed && s.removed}
          isHidden={this.state.removed}
          defaultOpen={this.state.open}
          title={sp.name || "New provider"}
          classes={s.sp_collapse}
          additionals= {
            <div className={s.sp_collapse_actions}>
              {(this.state.showRestore && sp.status !== 'initial') || this.state.removed ? <ActionRestore onClick={this.handleRestore} /> : null }
              {!this.state.removed && <CloseIcon onClick={this.handleRemove}/>}
            </div>
          }
         >
        <Form noValidate
          ref="form" className={sg.form_sp}
          onValidSubmit={(...args) => {
            this.setState({formChanged: false});
            this.handleSubmit(...args)
          }}
          onChange={() => this.state.changeListenReady && this.handleChange()}
          key={this.state.key}
          onKeyPress={this.onKeyPress}
        >
          {/* <ButtonForm onClick={this.props.handleSaveSp} /> */}
          <div className={sg.form_sp_body}>
            <div className={sg.form_sp_body_left}>
              <div className={sg.form_sp_item}>
                <div className={sg.form_sp_activity}>
                  {/* {this.state.sp.activities[0].changed ? "I AM CHANGED" : null} */}
                  <Select
                    className={sg.select}
                    floatingLabelText={"Activity"}
                    name={`activities[${0}]`}
                    floatingLabelShrinkStyle={labelShrinkStyle}
                    style={{
                      width: '100%',
                    }}
                    onChange={(e, v) => this.handleChangeActivity(e, v, 0)}
                    defaultValue={activitiesToRender[0]?.toString()}
                    previousValue={activitiesToRender[0]?.previousValue}
                  >
                    {
                      ACTIVITIES.map((item, i) => (
                        <MenuItem key={i} value={item} primaryText={item} />
                      ))
                    }
                  </Select>
                  <div className={sg.form_activities_add}>
                    <span className={sg.form_activities_add_button} onClick={this.handleAddActivity}>
                      <ContentAdd />
                    </span>
                  </div>
                </div>
                {activitiesToRender.slice(1).map((activity, i) => {
                  if (activity.removed) return null;
                  return (
                    <div key={i} className={sg.form_sp_activity}>
                      <Select
                        className={sg.select}
                        floatingLabelText={"Activity"}
                        name={`activities[${i + 1}]`}
                        floatingLabelShrinkStyle={labelShrinkStyle}
                        style={{
                          width: '100%',
                        }}
                        onChange={(e, v) => this.handleChangeActivity(e, v, i + 1)}
                        defaultValue={activitiesToRender[i + 1]?.toString()}
                        previousValue={activitiesToRender[i + 1]?.previousValue}

                      >
                        {
                          ACTIVITIES.map((item, i) => (
                            <MenuItem key={i} value={item} primaryText={item} />
                          ))
                        }
                      </Select>
                      <div className={sg.form_activities_add}>
                        <span className={sg.form_activities_add_button} onClick={this.handleRemoveActivity.bind(this, i + 1)}>
                          <ContentRemove />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={sg.form_sp_item}>
                <FormsyText
                  className={sg.address}
                  floatingLabelText="Address*"
                  validations="maxLength:255"
                  validationError="max 255 characters"
                  requiredError="required"
                  name="address"
                  required
                  fullWidth
                  floatingLabelStyle={labelStyle}
                  floatingLabelShrinkStyle={labelShrinkStyle}
                  style={{
                    width: '100%',
                  }}
                  autoComplete="address-line1"
                  defaultValue={sp.address}
                  previousValue={sp.origin.address}

                />
              </div>

              <div className={sg.form_sp_item_email}>
                <Email
                  floatingLabelText="E-mail*:"
                  name={'emails'}
                  emails={sp.emails}
                  originEmails={sp.origin.emails}
                  floatingLabelStyle={labelStyle}
                  floatingLabelShrinkStyle={labelShrinkStyle}
                  parentChangeCb={() => this.setState({formChanged: true})}
                />
              </div>
            </div>

            <div className={sg.form_sp_body_right}>
              <div className={sg.form_sp_item}>
                <FormsyText
                  className={sg.company}
                  floatingLabelText="Company name*"
                  validations="minLength:3,maxLength:255"
                  validationError="3 - 255 characters"
                  requiredError="required"
                  name="name"
                  required
                  fullWidth
                  floatingLabelStyle={labelStyle}
                  floatingLabelShrinkStyle={labelShrinkStyle}
                  style={{
                    width: '100%',
                  }}
                  autoComplete="organization"
                  defaultValue={sp.name}
                  previousValue={sp.origin.name}
                />
              </div>
              <div className={sg.form_sp_item}>
                <div className={sg.form_company_name}>
                  <FormsyText
                    className={sg.pic}
                    floatingLabelText="PIC"
                    validations="maxLength:255"
                    validationError="max 255 characters"
                    name="PIC"
                    fullWidth
                    floatingLabelStyle={labelStyle}
                    floatingLabelShrinkStyle={labelShrinkStyle}
                    style={{
                      width: '100%',
                    }}
                    autoComplete="PIC"
                    defaultValue={sp.PIC}
                    previousValue={sp.origin.PIC}
                  />
                </div>
              </div>
              <div className={sg.form_sp_item}>
                <Phone
                  className={sg.input}
                  floatingLabelText="Phone number:"
                  country={this.props.country}
                  phones={sp.phones}
                  originPhones={sp.origin.phones}
                  name="phones"
                  spPhone
                  floatingLabelStyle={labelStyle}
                  floatingLabelShrinkStyle={labelShrinkStyle}
                  parentChangeCb={() => this.setState({formChanged: true})}
                />
              </div>
             </div>
          </div>
          <div className={sg.form_sp_restrictions}>
            {this.state.newLimitation.includes(true) ?
              this.state.sp.limitations.map((limitation, index) => !limitation.removed &&
                (
                  <div style={{display: "flex", alignItems: "flex-end"}} key={(limitation._id || limitation.customId || 'lim') + index}>
                    <div className={sg.wrapper_restrictions}>
                    {
                      restrictionsService.map((item, i) => {
                        return <Restrictions
                          key={(limitation._id || limitation.customId || 'lim') + item.name}
                          floatingLabelText={item.label}
                          width={item.width}
                          name={`limitations[${index}][${item.name}]`}
                          defaultValue={limitation?.[item.name] ? limitation?.[item.name]?.toJSON() : limitation?.[item.name]}
                          previousValue={limitation?.[item.name]?.previousValue}
                          validations={item.validations}
                          validationError={item.validationError}
                        />
                      })
                    }
                    </div>
                    <div className={sg.form_activities_add}>
                      {index === 0
                        ? <span className={sg.form_activities_add_button} onClick={this.handleAddLimitation.bind(this)}>
                            <ContentAdd />
                          </span>
                        : <span className={sg.form_activities_add_button} onClick={this.handleRemoveLimitation.bind(this, index)}>
                            <ContentRemove />
                          </span>
                      }
                    </div>
                  </div>
                )
              )
            : null}
          </div>
          { this.state.formChanged ?
            <div className={sg.form_sp_actions}>
              <RaisedButton
                  label="Cancel"
                  disableTouchRipple
                  disableFocusRipple
                  type="button"
                  style={{
                    minWidth: '80px',
                    marginRight: '8px'
                  }}
                  buttonStyle={{
                    background: '#E6E6E6',
                    width: '80px',
                  }}
                  labelStyle={{
                    fontFamily: "Roboto Condensed",
                    fontSize: "12px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "#787878"
                  }}
                  onClick={this.handleCancel}
                />
                <RaisedButton
                  label="Save"
                  disableTouchRipple
                  disableFocusRipple
                  primary
                  type="submit"
                  style={{
                    minWidth: '80px',
                  }}
                  buttonStyle={{
                    width: '80px',
                  }}
                  labelStyle={{
                    fontFamily: "Roboto Condensed",
                    fontSize: "12px",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    color: "#ffffff"
                  }}
                />
            </div> : null
          }
        </Form>
      </Collapse>
    );
  }
}
