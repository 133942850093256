import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';
import IconButton from 'material-ui/IconButton';
import { List, ListItem } from 'material-ui/List';
import Drawer from 'material-ui/Drawer';
import s from './LeftBar.css';
import Menu_Sandvich from '../Icons/Menu_Sandvich';
import SettingsIcon from '../Icons/Settings';
import Main_Deck from '../Icons/Main_Deck';
import Cargo from '../Icons/Cargo';
import Ship from '../Icons/Ship';
import Link from '../Link';
import * as loginActions from '../../actions/login';
import history from '../../core/history';
import { getUnreadChatsCounter } from '../Chat/selectors';
import IconCalculator from '../Icons/IconCalculator';
import ChartIcon from '../Icons/ChartIcon';
import PortIcon from '../Icons/Port';
import DocumentsIcon from '../Icons/DocumentsIcon';
import ChatMailIcon from '../Icons/ChatMailIcon';

import {debounce} from './../../core/utils';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import ContactBookIcon from '../Icons/ContactBookIcon';

let countersFetched = false;

class LeftBar extends Component {
  static contextTypes = {
    path: PropTypes.string,
  };

  constructor(props, context) {
    super(props);
    this.state = { opened: 'closed', path: context.path };

    this.updateMainDeck = debounce(this.updateMainDeck, 2500).bind(this);
  }

  componentDidMount() {
    this.historyUnlistener = history.listen((location) => {
      this.setState({ path: location.pathname, opened: 'closed' });
    });
    !countersFetched && this.props.getCounters();
    countersFetched = true;
    this.bubleTimer = setTimeout(this.setBubble, 500);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if ((this.props.counters.total !== nextProps.counters.total) || this.props.unreadChatsCounter !== nextProps.unreadChatsCounter) {
      this.setBubble(nextProps);
    }
  }

  updateMainDeck = (ev, cb = this.handleToggle) => {
    ev.stopPropagation();
    PubSub.publish("monitor::updateList");
    cb && cb();
  }

  setBubble = (props = this.props) => {
    if (window.Tinycon) {
      window.Tinycon.setBubble((props.counters.total || 0) + (props.unreadChatsCounter || 0));
    }
  };

  componentWillUnmount() {
    this.historyUnlistener();
    clearTimeout(this.bubleTimer);
  }

  handleToggle = () => {
    this.setState({
      opened: this.state.opened === 'opened' ? 'closed' : 'opened',
    });
  };
  handleRequestChange = (open, reason) => {
    this.setState({ opened: open ? 'opened' : 'closed' });
  };

   updateMainDeck = (ev, cb = this.handleToggle) => {
      ev.stopPropagation();
      PubSub.publish("monitor::updateList");
      cb && cb();
  }

  render() {
    let totalNotif = 0;
    const { user, scroll, hover } = this.props;
    const path = this.state.path;
    const cargoBidsCount = this.props.counters?.cargoBids;
    const vesselBidsCount = this.props.counters?.vesselBids;
    const contractBidsCount = this.props.counters?.contractBids;
    const cargoTcBidsCount = this.props.counters?.cargoTcBids;
    const vesselTcBidsCount = this.props.counters?.vesselTcBids;
    totalNotif += this.props.counters?.total;
    return (
      <div
        className={cx(s.root, s[this.state.opened], user ? '' : s.not_logged, 'custom_menu_colors')}
      >
        <div className={s.header1}> {/* //TODO refactor */}
          {this.props.hideLogo ?
            <IconButton
              className={cx(
                s.menu_button,
                s.menu_button_main,
                scroll ? s.menu_button_main_scroll : '',
                this.props.loginPage ? s.menu_button_login : '',
                this.props.loginConnectPage ? s.menu_button_login_connect : '',
              )}
              onClick={this.handleToggle}
            >
              <Menu_Sandvich />
              {totalNotif ? <span className={s.notify_icon}>{totalNotif}</span> : null}
            </IconButton>

            :
           <IconButton className={s.menu_button} onClick={this.handleToggle}>
              <Menu_Sandvich />
              {user && totalNotif ? <span className={s.notify_icon}>{totalNotif}</span> : null}
            </IconButton>
          }
          {!this.props.hideLogo ?
            <Link handleClick={ev => this.updateMainDeck(ev, null)} to={ user ? '/main-deck/general' : '/'}>
              <span className={cx("logo_color_small",s.logo_color)} />
            </Link>
            : null}
        </div>
        <Drawer
          className={s.drawer}
          open={this.state.opened === 'opened'}
          swipeAreaWidth={50}
          docked={false}
          width={280}
          onRequestChange={this.handleRequestChange}
        >

          <div className={s.header}>
            <IconButton
              className={s.menu_button}
              onClick={this.handleToggle}
            >
              <Menu_Sandvich />
            </IconButton>
            <span className={s.title}>
              <Link
                handleClick={this.updateMainDeck}
                to={user ? '/main-deck/general' : '/'}
              >
                <span className={'logo_white_small'}></span>
              </Link>
            </span>
          </div>
          {user
            ? <div className={s.user_info}>
              <div>
                <div className={s.name}>{(user.name || '') + ' ' + (user.surname || '')}</div>
                <div className={s.email}>{user.email}</div>
                <div className={s.exit}>
                  <a onClick={this.props.logout}>exit</a>
                </div>
              </div>
            </div>
            : null}
          <div className={s.block}>
            <List className={s.menu}>
              <Link
                handleClick={this.updateMainDeck}
                to={'/main-deck/general'}
              >
                <ListItem
                  primaryText={"Trading Desk"}
                  initiallyOpen={path.indexOf('/main-deck') === 0}
                  primaryTogglesNestedList={true}
                  className={cx(s.menu_item,
                    path.indexOf('/main-deck') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<Main_Deck />}
                
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={'/emails/received/-/-/-/---/all-fleet/-/-/-'}
              >
                <ListItem
                  primaryText="Exchange"
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  leftIcon={<span><ChatMailIcon fill={"#FFF"} opacity={0.4}/></span>}
                  className={cx(s.menu_item,
                    path.indexOf('/emails') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={'/contacts/persons/-/-/-/---/all-fleet/-/-/-'}
              >
                <ListItem
                  primaryText="Contacts"
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  leftIcon={<span><ContactBookIcon fill="#FFF" opacity={0.4} /></span>}
                  className={cx(s.menu_item,
                    path.indexOf('/contacts') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={user ? '/my/cargo' : '/login'}
              >
                <ListItem
                  primaryText={`Cargo ${(cargoBidsCount) ? '(' + (cargoBidsCount) + ')' : ''}`}
                  initiallyOpen={path.indexOf('/my/cargo') === 0}
                  primaryTogglesNestedList
                  className={cx(s.menu_item,
                    path.indexOf('/cargo') !== -1
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<Cargo />}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to="/fleetmonitor/allfleet/"
              >
                <ListItem
                  primaryText={`Fleet`}
                  primaryTogglesNestedList
                  className={cx(s.menu_item, path.indexOf('/fleetmonitor') !== -1 || path.indexOf('/vessel') === 0
                    ? cx(s.active, 'active_menu_item')
                    : null)}
                  leftIcon={<Ship />}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={user ? '/contracts/voyage' : '/login'}
              >
                <ListItem
                  primaryText={`Documents ${contractBidsCount ? '(' + contractBidsCount + ')' : ''}`}
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  className={cx(s.menu_item,
                    path.indexOf('/contracts') !== -1 && path.indexOf('/bill-of-lading') === -1
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<DocumentsIcon fill={"#FFF"}/>}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={user ? '/calculator/-/-/-' : '/login'}
              >
                <ListItem
                  primaryText="Calculator"
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  className={cx(s.menu_item,
                    path.indexOf('/calculator') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<IconCalculator />}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={user ? '/markets' : '/login'}
              >
                <ListItem
                  primaryText="Markets"
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  className={cx(s.menu_item,
                    path.indexOf('/markets') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<ChartIcon />}
                />
              </Link>
              <Link
                handleClick={this.handleToggle}
                to={'/port'}
              >
                <ListItem
                  primaryText="Ports"
                  initiallyOpen={false}
                  primaryTogglesNestedList
                  className={cx(s.menu_item,
                    path.indexOf('/port') === 0
                      ? cx(s.active, 'active_menu_item')
                      : null)}
                  leftIcon={<PortIcon />}
                />
              </Link>
              {user ?
                <Link
                  handleClick={this.handleToggle}
                  to={'/settings/profile'}
                >
                  <ListItem
                    primaryText="Settings"
                    initiallyOpen={path.indexOf('/settings') === 0}
                    primaryTogglesNestedList
                    className={cx(s.menu_item,
                      path.indexOf('/settings') === 0
                        ? cx(s.active, 'active_menu_item')
                        : null)}
                    leftIcon={<span><SettingsIcon /></span>}
                  />
                </Link>
              : null}
            </List>
          </div>

          <div className={s.register}>
            <Link
              handleClick={this.handleToggle}
              to="/login"
              className={s.link}
            >
              LOGIN
            </Link>
            <Link
              handleClick={this.handleToggle}
              className={cx(s.link, s.sign_up)}
              to="/register"
            >
              REGISTER
            </Link>
          </div>
          <div className={s.spacer} />
          <div className={s.footer}>
            <div>
              <Link handleClick={this.handleToggle} to="/help">
                Help & Feedback
              </Link>
            </div>
            <div>
              <Link handleClick={this.handleToggle} to="/legal">
                Terms & Conditions
              </Link>
            </div>
            <div>
              <Link handleClick={this.handleToggle} to="/rules-regulations">
                Rules & Regulations
              </Link>
              &nbsp;
            </div>
            <div>
              <Link handleClick={this.handleToggle} to="/copyright">
                Copyright Policy
              </Link>
              &nbsp;
            </div>
            <div>
              <Link handleClick={this.handleToggle} to="/privacy">
                Privacy Policy
              </Link>
              &nbsp;
            </div>
            <div>
              <Link handleClick={this.handleToggle} to="/cookies">
                Cookies Policy
              </Link>
              &nbsp;
            </div>
          </div>
        </Drawer>

      </div>
    );
  }
}

LeftBar.propTypes = {};
LeftBar.defaultProps = {};

export default connect(state => ({
  user: state.login.user,
  counters: state.login.counters,
  cargoRequestList: state.cargo.requestList,
  cargoTcOfferList: state.cargo.tcOfferList,
  vesselRequestList: state.vessel.requestList,
  vesselTcOfferList: state.vessel.tcOfferList,
  contractList: state.contracts.contractList,
  unreadChatsCounter: getUnreadChatsCounter(state),
}), loginActions)(
  withStyles(s)(LeftBar),
);
