exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CqCkt{display:-ms-flexbox;display:flex}._3l_oQ{padding-right:0 !important;border-right:1px solid var(--border-color);height:100%;-webkit-box-shadow:6px 0px 18px rgba(128,128,128,0.14);box-shadow:6px 0px 18px rgba(128,128,128,0.14);z-index:10;width:50%}._26-NJ{padding-left:0 !important;height:100%;z-index:10;width:50%}._3l_oQ._2Lfdb,._26-NJ._2Lfdb{z-index:11 !important}\n", ""]);

// Exports
exports.locals = {
	"wrapper": "CqCkt",
	"left": "_3l_oQ",
	"right": "_26-NJ",
	"main": "_2Lfdb"
};