exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._33qJI{position:relative;width:100%;z-index:999}._33qJI .sGQm6{padding:13px;background:#FFFDD6;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}@media (max-width: 600px){._33qJI .sGQm6{-ms-flex-align:start;align-items:flex-start}}._33qJI .sGQm6+.sGQm6{border-top:1px solid white}._33qJI ._3z80I{cursor:pointer}._33qJI ._1Iyjv{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;width:100%}\n", ""]);

// Exports
exports.locals = {
	"root": "_33qJI",
	"message": "sGQm6",
	"close": "_3z80I",
	"upgrade_subscription_message": "_1Iyjv"
};