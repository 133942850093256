import React, { Component } from 'react'
import s from './SplitPane.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import styled, { css } from 'styled-components';
import TableActionsHead from './TableActionsHead';
import Hoverable from './Hoverable';
import CustomTooltip from './../Common/CustomTooltip';
import NavigationClose from 'material-ui/svg-icons/navigation/close';

const media = `
  @media screen and (max-width: 1290px) {
    width: 650px;
  }
`;

export const HeaderStyles = styled.header`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${props => props.bg ?? 'var(--bg-light-grey)'};
  padding: ${props => props.p ?? '0px 9px'};
  width: max-content;
  min-width: 100%;
  color: var(--text-dark);
  font-size: 14px;
  font-weight: 700;
  .close-pane-icon {
    min-width: 24px;
    justify-self: flex-end;
    margin-left: auto;
    cursor: pointer;
    margin-right: 1px;
    margin-right: 2px;
    margin-top: -1px;
  }
  ${props => {
    let styles = ``;

    if (props.useBorder) {
      styles += `
        border-bottom: 1px solid var(--stroke-light-gray2);
      `;
    }

    return css`${styles}`;
  }}
`;

const Body = styled.div`
  width: auto;
  min-width: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: ${props => props.scrollable ? 'auto !important' : 'hidden'};
  justify-content: flex-start;
  ${
    props => {
      let styles = ``;

      if (props.scrollable) {
        styles += `
          ${HeaderStyles}, ${Body} {
            ${media};
          }
        `;
      }
      if (props.main) {
        styles += `
          z-index: 99999 !important;
        `;
      }

      return css`${styles}`;
    }
  }
`;

export class SplitPane extends Component {
  render() {
    const { left, right, className = '', main = "left", leftPaneClassName = '', rightPaneClassName = '', ...rest } = this.props;
    return (
      <div className={cx("row", s.wrapper, className)} {...rest}>
        <div className={cx("col-6", leftPaneClassName, s.left, main === "left" ? s.main : '')}>
          {
            left
          }
        </div>
        <div className={cx("col-6", rightPaneClassName, s.right,  main === "right" ? s.main : '')} {...rest}>
          {
            right
          }
        </div>
      </div>
    )
  }
}

export function Header(props) {
  const { condition, hoverableProps, children, conditionalChildren, withoutStyles = true, ...rest } = props;

  const isRenderProp = typeof children === 'function';

  if (isRenderProp) {
    return children(condition);
  }
  else if (typeof condition === 'undefined') {
    return <HeaderStyles {...rest}>{children} {props.onClose ? <NavigationClose className={"close-pane-icon"} onClick={props.onClose} /> : null}</HeaderStyles>;
  }

  const defaultRenderer = (children) => (
    hoverableProps
      ? React.Children.map(children, child => {
        const childEl = child.props.noHover ? child : <Hoverable disabled={child.props.disabled} {...hoverableProps}>{React.cloneElement(child)}</Hoverable>;

        if (child.props.useTooltip) {
          const { tooltipProps } = child.props;
          return (
            <CustomTooltip
              {...tooltipProps}
            >
              {
                childEl
              }
            </CustomTooltip>
          );
        }

        return childEl;
      })
      : children
  );

  return (
    <div>
      <div>
        {condition ? (
          <TableActionsHead {...rest}>
          {
            defaultRenderer(conditionalChildren)
          }
        </TableActionsHead>) : (
          <HeaderStyles {...rest}>
            {
              defaultRenderer(children)
            }
          </HeaderStyles>
        )}
      </div>
    </div>
  )
}

export function Pane(props) {
  const { headerProps = {}, extraHeaderProps, scrollable = false, children, className = '', bodyProps = {}, style = {}, main, ...rest } = props;

  return (
    <Wrapper main={main} scrollable={scrollable} style={style} className={className} {...rest}>
      <Header {...headerProps} />
      {
         extraHeaderProps && <Header {...extraHeaderProps} />
      }
      <Body {...bodyProps}>
        {
          children
        }
      </Body>
    </Wrapper>
  )
}


export default withStyles(s)(SplitPane);
