import React, { Component } from 'react';
import Dialog from './../../Common/Dialog';
import RaisedButton from "../../Common/RaisedButton";
import v from './VesselEdit.scss';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

const contentStyle = {
  fontWeight: 'normal',
  color: '#333333',
  textAlign: 'center',
  fontSize: '14px',
  whiteSpace: 'pre-wrap',
};

export class LeavePageConfirmDialog extends React.Component {
  state = {
    show: false
  }
  promiseCallbacks = {}

  componentDidMount() {
    if (this.props.attachRef) {
      this.props.attachRef(this);
    }
  }

  close = () => {
    this.setState({
      show: false
    }, () => this.promiseCallbacks && this.promiseCallbacks?.reject(false))
  }

  accept = () => {
    this.setState({
      show: false
    }, () => this.promiseCallbacks && this.promiseCallbacks?.resolve(true))
  }

  show = () => {
    return new Promise((resolve, reject) => {
      this.promiseCallbacks = {
        resolve,
        reject
      };
      this.setState({
        show: true
      })
    })
  }

  render() {
    const {accept, close} = this;
    const {show} = this.state;
    const {children = 'Are you sure you want to leave?', title = 'ATTENTION', titleClass= '', bodyClass = ''} = this.props;
    return <Dialog
    title={title}
    titleClassName={cx(v.dialog_title, titleClass)}
    handleClose={close}
    open={show}
    contentClassName={v.dialog_content}
    bodyClassName={cx(v.dialog_body, bodyClass)}
    actions={
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <RaisedButton label="No" secondary onClick={close} />
        <RaisedButton label="Yes" style={{marginLeft: '8px'}} primary onClick={accept} />
      </div>
    }
  >
      {children}
  </Dialog>
  }
}

export default withStyles(v)(LeavePageConfirmDialog);
