exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3qdFt{-webkit-box-sizing:border-box;box-sizing:border-box}._3qdFt>div{border-radius:8px !important;padding-bottom:10px}._3qdFt>div>div:first-child{padding:0 !important}._3qdFt>div>div:first-child svg{z-index:10}._3qdFt._2E9ff>div{padding-top:8px !important}._35Isu>div{border-radius:8px !important}._3nF9P{width:50vw !important;-webkit-transition:none !important;transition:none !important;padding:0 !important}._3nF9P._2Oem_{left:0 !important}._3nF9P._2rmEI{left:50% !important}._3nF9P._2rmEI>div:first-child:after{-webkit-box-shadow:0px 0px 20px 2px rgba(0,0,0,0.2);box-shadow:0px 0px 20px 2px rgba(0,0,0,0.2)}._3nF9P>div:first-child{height:100%;clip-path:inset(0)}._3nF9P>div:first-child:after{content:\" \";height:100%;width:0px;position:absolute;left:0;top:0;z-index:1500}._3nF9P>div>div:first-child{background:white;width:100% !important;max-width:100% !important;height:100% !important;margin:0 !important;-webkit-transition:none !important;transition:none !important;-webkit-transform:none !important;transform:none !important}._3nF9P>div>div:first-child>div:first-child{border-radius:0 !important;-webkit-box-shadow:none !important;box-shadow:none !important;height:100% !important}._3nF9P>div>div:first-child>div:first-child>div{height:100% !important;max-height:100% !important;padding:0 !important;font-size:inherit !important}\n", ""]);

// Exports
exports.locals = {
	"dialogContent": "_3qdFt",
	"empty_title": "_2E9ff",
	"dialog_container": "_35Isu",
	"rightHalfDialog": "_3nF9P",
	"left": "_2Oem_",
	"right": "_2rmEI"
};